import React, { useEffect, useState } from "react"
import "./BlocTechnique.scss"
import ImgCustom from "../../../../components/imgCustom/imgCustom"

const BlockTechnique = ({ data , imageTechnique , imageTechniqueAlt, txtTechnique}) => {
  const [hide, setHide] = useState(false)
  const [scrollTop, setScrollTop] = useState(false)
  const [temp, setTemp] = useState(false)

  const [isMobile, setIsMobile] = useState(false)
  const resizeEvent = () => {
    const tablet = 1170
    const mobile = 768

    if (window.innerWidth > tablet) {
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= tablet && window.innerWidth >= mobile) {
      setIsMobile(false)
      return
    }
    if (window.innerWidth <= mobile) {
      setIsMobile(true)
      return
    }
  }
  useEffect(() => {
    resizeEvent()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        resizeEvent()
      })
    }
  }, [])

  const formatLigne = (txt: string) => {
    if (txt.includes(":")){
      return <li className="mt-2"> {txt.substring(0,txt.indexOf(":")+1)} <span className="ff-gbook"> {txt.substring(txt.indexOf(":")+1)} </span></li>
    }
    else {
      return <li className="ff-gbook mt-2"> {txt} </li>
    }
  }

  if (data) {
    return (
      <div className={"ta-c blocTechnique" + (isMobile ? " mobile technical-blockMobile" : " bcg-2 technical-block")} >
        <div className={"technical jc-c ai-c d-f fd-r ta-l" + (isMobile ? " mr-2 ml-2" : "")}>
            {!isMobile && 
              <div className={"imageProduitT" + (isMobile ? "Mobile" : " ")}>
                <ImgCustom
                  src={imageTechnique}
                  alt={imageTechniqueAlt}
                />
              </div> 
            }
            <div className={"text wrapper bcg-1" + (isMobile ? " blocFicheTechniqueMobile blocTextMobile" : " blocFicheTechnique ml-4 blocTextTechnique pl-2")}>
              <div className={"ff-gb " + (isMobile ? " titreBlocTechniqueMobile fs-24 ff-gb mt-5 pl-2 c-4" : " titreBlocTechnique fs-24 ff-gb mt-5 c-4")} dangerouslySetInnerHTML={{__html: "Fiche technique",}}></div>
                <ul className={"ff-gm cg-3" + (isMobile ? " mb-5" : " mb-5 contenuFicheTechnique")}>
                {txtTechnique.map((el, key) => (
                  formatLigne(el)
                ))}
                </ul>
            </div>
        </div>
      </div>
    )
  }
  return <></>
}

export default BlockTechnique
